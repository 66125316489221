@charset "UTF-8";
@font-face {
  font-family: 'SportingGrotesque';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Sporting Grotesque"), local("Sporting-Grotesque"), url("./fonts/Sporting_Grotesque-Regular_web.woff2") format("woff2"), url("./fonts/Sporting_Grotesque-Regular_web.woff") format("woff"); }

@font-face {
  font-family: 'SportingGrotesque';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("Sporting Grotesque Bold"), local("Sporting-Grotesque-Bold"), url("./fonts/Sporting_Grotesque-Bold_web.woff2") format("woff2"), url("./fonts/Sporting_Grotesque-Bold_web.woff") format("woff"); }

@font-face {
  font-family: 'HappyTimes';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("Happy Times"), local("Happy-Times"), url("./fonts/happy-times-at-the-ikob-webfont.woff2") format("woff2"), url("./fonts/happy-times-at-the-ikob-webfont.woff") format("woff"); }

@font-face {
  font-family: 'HappyTimes';
  font-style: italic;
  font-weight: 400;
  font-display: auto;
  src: local("Happy Times Italic"), local("Happy-Times-Italic"), url("./fonts/happy-times-at-the-ikob_italic-webfont.woff2") format("woff2"), url("./fonts/happy-times-at-the-ikob_italic-webfont.woff") format("woff"); }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  margin: 0;
  font-family: SportingGrotesque;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #000;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #000;
    text-decoration: none;
    text-shadow: #dee2e6 2px 5px; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.hidden {
  display: none; }

body {
  background-size: cover; }

@media (max-width: 620px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 621px) {
  .hidden-desktop {
    display: none; } }

.text-happy-times {
  font-family: HappyTimes, sans-serif; }

.list-unstyled {
  list-style: none;
  padding-left: 0; }

.mb-1 {
  margin-bottom: 10px; }

.black-bg {
  background-color: #000;
  color: #fff; }
  .black-bg a {
    text-shadow: -0.5px 0 #fff, 0 0.5px #fff, 0.5px 0 #fff, 0 -0.5px #fff; }

.navbar {
  padding: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }
  @media (max-width: 620px) {
    .navbar {
      padding: 0.5rem; } }

.navbar__brand {
  text-transform: uppercase;
  font-size: 2.5rem; }

@media (max-width: 620px) {
  .navbar__globe,
  .navbar__futur2010 {
    height: 50px; }
  .navbar__globe {
    width: 50px; } }

.main-menu {
  padding-top: 2.5rem;
  padding-left: 3rem;
  position: fixed;
  top: 148px;
  left: 0;
  width: 160px; }
  @media (max-width: 620px) {
    .main-menu {
      position: static; } }

.main-menu__social-link {
  margin-bottom: .75rem; }

.main-menu__magazine-list {
  list-style: none;
  padding-left: 0;
  margin-top: 3rem;
  margin-bottom: 3rem;
  font-weight: 400; }

@media (min-width: 621px) {
  .main-menu__internal-links {
    margin-top: 100px; } }

.main-menu-mobile {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0.5rem;
  background-color: #fff;
  font-size: .7rem; }
  .main-menu-mobile .main-menu__social-link,
  .main-menu-mobile .main-menu__internal-links > div {
    display: inline-block;
    margin-right: 5px; }

.magazine-viewer {
  display: -ms-flexbox;
  display: flex;
  max-width: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.magazine-viewer__page-holder {
  position: relative; }
  .magazine-viewer__page-holder img {
    max-width: 100%;
    max-height: calc(100vh - 158px); }

.magazine-viewer__page-center-effect div {
  position: absolute;
  top: 0;
  bottom: 0; }
  .magazine-viewer__page-center-effect div:first-child {
    left: calc(50% - 30px);
    right: 50%;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent); }
  .magazine-viewer__page-center-effect div:last-child {
    left: 50%;
    right: calc(50% - 30px);
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent); }

.magazine-viewer__arrow-placeholder {
  width: 120px; }

@media (max-width: 620px) {
  .magazine-viewer__next img,
  .magazine-viewer__previous img {
    width: 60px;
    height: 60px; } }

.content {
  padding-top: 148px; }
  @media (max-width: 620px) {
    .content {
      padding-top: 50px; } }

.content--with-main-menu {
  padding-left: 160px;
  padding-right: 5rem; }
  @media (max-width: 620px) {
    .content--with-main-menu {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }

.magazine-list-holder {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }

.magazine-list {
  line-height: 2.3;
  margin-top: 20px; }
  @media (min-width: 621px) {
    .magazine-list {
      margin-top: 30px;
      max-width: 600px;
      line-height: 3; } }

.magazine-list__item {
  display: inline; }
  .magazine-list__item sup {
    font-family: HappyTimes, serif; }
  @media (min-width: 621px) {
    .magazine-list__item {
      white-space: nowrap; } }

@media (min-width: 621px) {
  .magazine-0 {
    margin-left: 40px; }
  .magazine-3 {
    margin-left: 40px; } }

.mixes {
  list-style: none;
  margin-top: 40px; }
  .mixes li {
    position: relative; }
    .mixes li::before {
      content: '▶';
      position: absolute;
      left: -20px; }

.archives-mixes {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  font-size: 11px; }
  .archives-mixes .mixes {
    margin-top: 20px; }

.archives-mixes-title {
  font-size: 14px;
  text-align: center;
  margin-top: 40px; }

.manifest-video {
  position: fixed;
  right: 60px;
  left: 50%;
  top: 150px;
  height: 50%;
  z-index: -100; }
  @media (max-width: 620px) {
    .manifest-video {
      display: none; } }

.manifest-video-poster {
  position: fixed;
  right: 0;
  left: 30%;
  top: 150px;
  height: 50%;
  background-image: url("/static/images/manifest_poster.jpg");
  background-size: cover;
  z-index: -100; }
  @media (min-width: 621px) {
    .manifest-video-poster {
      display: none; } }

.manifest-holder {
  margin-top: 40px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .manifest-holder img {
    width: 500px;
    max-width: 100%; }
    @media (min-width: 621px) {
      .manifest-holder img {
        margin-right: 100px;
        margin-bottom: 100px; } }

.image-log {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  @media (max-width: 620px) {
    .image-log {
      margin-top: 20px; } }

.image-log-entry {
  margin-bottom: 10px; }
  .image-log-entry img {
    max-width: 100%;
    max-height: 400px; }
